import React, { useState, useEffect } from "react";
import Alert from "./Alert.jsx";


function Monitoring({ token }) {



  return (
        <div className="container text-center mt-3 mb-3">
            В разработке
        </div>
  );
}

export default Monitoring;
