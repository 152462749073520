import React, { useState, useEffect } from "react";
import Alert from "./Alert.jsx";
import useFetch from "react-fetch-hook";
import createTrigger from "react-use-trigger";
import useTrigger from "react-use-trigger/useTrigger";
import Base from './Base';
const requestTriggerDevice = createTrigger();
const requestTriggerService = createTrigger();
const requestTriggerReglament = createTrigger();

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

function ReglamentLog(props) {
    const { token, getURL } = Base();

    const [AlertUpdateReglamentLog, setAlertUpdateReglamentLog] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

    const updateReglamentLog = async e => {
        e.preventDefault();
        var work_id = e.target.elements.work_id.value;
        var measure = e.target.elements.measure.value;
        var date = e.target.elements.date.value;
        var description = e.target.elements.description.value;
        console.log(work_id, measure, date,description)
        let result = await fetch(`${getURL()}api/service/works`, { method: 'POST', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify({"work_id": work_id, "measure": measure, "description": description, "date": date})})
        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertUpdateReglamentLog({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Регламент изменён.'}});
            await timeout(1000);
            requestTriggerReglament()
            setAlertUpdateReglamentLog({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

        } else {
            setAlertUpdateReglamentLog({'error':{'show': true, 'text': 'Произошла ошибка при изменении регламента. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    const deleteReglamentLog = async (id) => {
        let result = await fetch(`${getURL()}api/service/works`, { method: 'DELETE', body: JSON.stringify({'work_id': id}), headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}})
        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertUpdateReglamentLog({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Работа удалена.'}});
            await timeout(1000);
            requestTriggerReglament()
            setAlertUpdateReglamentLog({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

        } else {
            setAlertUpdateReglamentLog({'error':{'show': true, 'text': 'Произошла ошибка при удалении работы. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    return (
        <span className="list-group-item list-group-item-action" aria-current="false" key={props.work.id}>
            <div className="d-flex w-100 justify-content-between"  data-bs-toggle="collapse" data-bs-target={"#collapseWorkLogHistory"+props.work.id}>
                <h5 className="mb-1">{props.work.reglament.name}</h5>
            </div>
            <div className="d-flex w-100 justify-content-between"  data-bs-toggle="collapse" data-bs-target={"#collapseWorkLogHistory"+props.work.id}>
                <span>{ new Date( Date.parse(props.work.date)).toLocaleString("ru", {year: 'numeric',month: 'long',day: 'numeric'}) } {props.work.measure > 0 ? '(' + props.work.measure + ' ' + props.device.measure_units + ')': ''}</span>
            </div>
            { props.work.description && <p className="m-0 text-start"><small>{props.work.description}</small></p>}
            <div className="collapse" id={"collapseWorkLogHistory"+props.work.id}>
                <div className="w-100 justify-content-between mt-3">
                    <form onSubmit={updateReglamentLog}>
                        <input type="text" className="visually-hidden" name="work_id"  defaultValue={props.work.id} />
                        <div className="form-floating mb-3">
                            <input type="date" name="date" className="form-control" id={"DateReglamentLog"+props.work.id} defaultValue={props.work.date}/>
                            <label htmlFor={"DateReglamentLog"+props.work.id}>Дата проведения работ</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="number" step="1" name="measure" className="form-control" id={"MeasureReglamentLog"+props.work.id} defaultValue={props.work.measure} />
                            <label htmlFor={"MeasureReglamentLog"+props.work.id}>{props.device.measure_units} на момент проведения работ</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input className="form-control" name="description"  id={"CommentReglamentLog"+props.work.id} placeholder="Комментарий к работе"  defaultValue={props.work.description} />
                            <label htmlFor={"CommentReglamentLog"+props.work.id}>Комментарий к работе</label>
                        </div>
                        <div className="d-flex w-100 justify-content-between">
                            <button type="button" className="btn btn-danger"  onClick={e => deleteReglamentLog(props.work.id)}>Удалить</button>
                            <button type="submit" className="btn btn-success" >Сохранить</button>
                        </div>
                        <Alert source={AlertUpdateReglamentLog} />
                    </form>
                </div>
            </div>
        </span>
);

}

function Reglament(props) {
    const { token, getURL } = Base();
    const [AlertUpdateReglament, setAlertUpdateReglament] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});
    const [AlertAddReglamentLog, setAlertAddReglamentLog] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

    const updateReglament = async e => {
        e.preventDefault();
        var reglament_id = e.target.elements.reglament_id.value;
        var name = e.target.elements.reglament_name.value;
        var interval_months = e.target.elements.reglament_interval_months.value;
        var interval_measure = e.target.elements.reglament_interval_measure.value;
        var description = e.target.elements.reglament_description.value;
        let result = await fetch(`${getURL()}api/service/reglaments`, { method: 'POST', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify({"reglament_id": reglament_id, "name": name, "description": description, "interval_measure": interval_measure, "interval_months": interval_months})})
        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertUpdateReglament({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Регламент изменён.'}});
            await timeout(1000);
            requestTriggerReglament()
            setAlertUpdateReglament({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

        } else {
            setAlertUpdateReglament({'error':{'show': true, 'text': 'Произошла ошибка при изменении регламента. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    const deleteReglament = async (id) => {
        let result = await fetch(`${getURL()}api/service/reglaments`, { method: 'DELETE', body: JSON.stringify({'reglament_id': id}), headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}})

        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertUpdateReglament({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Регламент удалён.'}});
            await timeout(1000);
            requestTriggerReglament()
            setAlertUpdateReglament({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

        } else {
            setAlertUpdateReglament({'error':{'show': true, 'text': 'Произошла ошибка при удалении регламента. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    const addReglamentLog = async e => {
        e.preventDefault();
        var reglament_id = e.target.elements.reglament_id.value;
        var measure = e.target.elements.measure.value;
        var date = e.target.elements.date.value;
        var description = e.target.elements.description.value;

        let result = await fetch(`${getURL()}api/service/works`, { method: 'PUT', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify({"reglament_id": reglament_id, "description": description, "measure": measure, "date": date})})
        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertAddReglamentLog({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Работа добавлена.'}});
            e.target.reset()
            await timeout(1000);
            requestTriggerDevice()
            let collapse = document.getElementById("collapseNewReglamentLog"+reglament_id);
            collapse.classList.toggle('show')
            setAlertAddReglamentLog({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

        } else {
            setAlertAddReglamentLog({'error':{'show': true, 'text': 'Произошла ошибка при добавлении работы. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    return (
        <span className="list-group-item list-group-item-action" aria-current="false" key={props.reglament.id}>
            <div className="d-flex w-100 justify-content-between">
                <div className="d-flex w-100 justify-content-between mb-1">
                    <div className="col-md-auto">
                        <h5 className="mb-1 text-start">
                            {props.reglament.name}
                        </h5>
                        <p className="mb-1 text-start"><small>
                            Каждые {props.reglament.interval.measure ? props.reglament.interval.measure + ' ' + props.device.measure_units  : ''} {props.reglament.interval.measure && props.reglament.interval.months ? ' или': ''} {props.reglament.interval.months ? props.reglament.interval.months + ' месяц(а/ев)'   : ''}
                        </small></p>
                    </div>
                    <div className="col col-lg-2 text-end">
                        <div className="btn-group btn-group-sm " role="group" aria-label="Basic mixed styles example">
                            <button type="button" className="btn btn-outline-dark" data-bs-toggle="collapse" data-bs-target={"#collapseNewReglamentLog"+props.reglament.id}><i className="bi bi-plus-lg"></i></button>
                            <button type="button" className="btn btn-outline-dark"  data-bs-toggle="collapse" data-bs-target={"#collapseReglament"+props.reglament.id}><i className="bi bi-pencil"></i></button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="progress" role="progressbar" aria-label="Progress" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                <div className={"progress-bar "+ (props.reglament.expiration_percent >= 100 ? "bg-danger" : (props.reglament.expiration_percent > 80 ? "bg-warning" : "bg-success"))} style={{width: props.reglament.expiration_percent+"%"}}>
                    {props.reglament.expiration_percent}%
                </div>
            </div>

            <div className="collapse" id={"collapseReglament"+props.reglament.id}>
                <div className="w-100 justify-content-between mt-3">
                    <form onSubmit={updateReglament}>
                        <input type="text" className="visually-hidden" name="reglament_id" defaultValue={props.reglament.id} />
                        <div className="mb-3">
                            <input className="form-control" name="reglament_name"  id="DataList" placeholder="Название работы"  defaultValue={props.reglament.name} />
                        </div>
                        <div className="input-group mb-3">
                            <input type="number" step="1" name="reglament_interval_months" className="form-control" defaultValue={props.reglament.interval.months} />
                            <span className="input-group-text" id="basic-addon2">Интервал в месяцах</span>
                        </div>
                        <div className="input-group mb-3">
                                    <input type="number" step="1" name="reglament_interval_measure" className="form-control" defaultValue={props.reglament.interval.measure} />
                                    <span className="input-group-text" id="basic-addon2">Интервал в {props.device.measure_units}</span>
                        </div>
                        <div className="mb-3">
                                    <input className="form-control" name="reglament_description" placeholder="Описание"  defaultValue={props.reglament.description} />
                        </div>
                        <div className="d-flex w-100 justify-content-between">
                            <button type="button" className="btn btn-danger"  onClick={e => deleteReglament(props.reglament.id)}>Удалить</button>
                            <button type="submit" className="btn btn-success" >Сохранить</button>
                        </div>
                        <Alert source={AlertUpdateReglament} />
                    </form>
                </div>
            </div>

            <div className="collapse" id={"collapseNewReglamentLog"+props.reglament.id}>
                <div className="w-100 justify-content-between mt-3">
                    <form onSubmit={addReglamentLog}>
                        <input type="text" className="visually-hidden" name="reglament_id"  defaultValue={props.reglament.id} />
                        <input type="text" className="visually-hidden" name="device_id"  defaultValue={props.device.id} />
                        <div className="form-floating mb-3">
                            <input type="date" name="date" className="form-control" id={"DateReglamentLog"+props.reglament.id} defaultValue={new Date().toISOString().slice(0, 10)}/>
                            <label htmlFor={"DateReglamentLog"+props.reglament.id}>Дата проведения работ</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="number" step="1" name="measure" className="form-control" id={"MeasureReglamentLog"+props.reglament.id} defaultValue={props.device.measure} />
                            <label htmlFor={"MeasureReglamentLog"+props.reglament.id}>{props.device.measure_units} на момент проведения работ</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input className="form-control" name="description"  id={"CommentReglamentLog"+props.reglament.id} placeholder="Описание"  defaultValue="" />
                            <label htmlFor={"CommentReglamentLog"+props.reglament.id}>Описание</label>
                        </div>
                        <div className="d-flex w-100 justify-content-between">
                            <button type="submit" className="btn btn-success" >Добавить</button>
                        </div>
                        <Alert source={AlertAddReglamentLog} />
                    </form>
                </div>
            </div>

        </span>
);

}

function Device(props) {
    const { token, getURL } = Base();

    const [AlertAddReglament, setAlertAddReglament] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});
    const [showIssuing, setShowIssuing] = useState(true);
    const [reglamentLogPage, setReglamentLogPage] = useState(1);
    const [AlertUpdateDevice, setAlertUpdateDevice] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});


    const resultReglaments = useFetch(`${getURL()}api/service/reglaments?device_id=${props.device.id}&only_issuing=${(showIssuing ? "Y" : "N")}`, {headers: {'Authorization': `Bearer ${token}`}, depends: [useTrigger(requestTriggerDevice), useTrigger(requestTriggerReglament)]});
    const resultWorks = useFetch(`${getURL()}api/service/works?device_id=${props.device.id}&page=${reglamentLogPage}`, {headers: {'Authorization': `Bearer ${token}`}, depends: [useTrigger(requestTriggerDevice)]});



    const updateDevice = async e => {
        e.preventDefault();
        var device_id = e.target.elements.device_id.value;
        var name = e.target.elements.name.value;
        var production_year = e.target.elements.production_year.value;
        var measure = e.target.elements.measure.value;
        var measure_units = e.target.elements.measure_units.value;
        var description = e.target.elements.description.value;
        let result = await fetch(`${getURL()}api/service/devices`, { method: 'POST', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify({"device_id": device_id, "name": name, "description": description, "measure": measure, "measure_units": measure_units, "production_year": production_year})})
        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertUpdateDevice({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Устройство изменено.'}});
            await timeout(1000);
            requestTriggerService()
            setAlertUpdateDevice({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});
        } else {
            setAlertUpdateDevice({'error':{'show': true, 'text': 'Произошла ошибка при изменении устройства. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    const deleteDevice = async (id) => {
        let result = await fetch(`${getURL()}api/service/devices`, { method: 'DELETE', body: JSON.stringify({'device_id': id}), headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json'}})
        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertUpdateDevice({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Устройство удалено.'}});
            await timeout(1000);
            requestTriggerService()
            setAlertUpdateDevice({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

        } else {
            setAlertUpdateDevice({'error':{'show': true, 'text': 'Произошла ошибка при удалении устройства. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    const addReglament = async e => {
        e.preventDefault();
        var device_id = e.target.elements.device_id.value;
        var name = e.target.elements.name.value;
        var interval_months = e.target.elements.interval_months.value;
        var interval_measure = e.target.elements.interval_measure.value;
        var description = e.target.elements.description.value;
        let result = await fetch(`${getURL()}api/service/reglaments`, { method: 'PUT', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify({"device_id": device_id, "name": name, "description": description, "interval_measure": interval_measure, "interval_months": interval_months})})
        let json_data = await result.json()
        if (json_data.error === null) {
            setAlertAddReglament({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Регламент добавлен.'}});
            e.target.reset()
            await timeout(1000);
            requestTriggerDevice()
            let collapse = document.getElementById("collapseNewReglament"+device_id);
            collapse.classList.toggle('show')
            setAlertAddReglament({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

        } else {
            setAlertAddReglament({'error':{'show': true, 'text': 'Произошла ошибка при добавлении регламента. '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }


    useEffect(() => {
    }, [showIssuing, reglamentLogPage]);

    return (
    <div className="row mt-5">
          <div className="col">
            <div className="container text-center">
                <div className="row">
                    <div className="col">
                        <p className="h1 text-start">{props.device.name}, {props.device.production_year} <button type="button" className="btn"  data-bs-toggle="collapse" data-bs-target={"#collapse_device"+props.device.id}><i className="bi bi-pencil"></i></button></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="lead text-start">{new Intl.NumberFormat("ru").format(props.device.measure)} {props.device.measure_units}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="lead text-start">{props.device.description}</p>
                    </div>
                </div>
                <div className="collapse" id={"collapse_device"+props.device.id}>
                    <div className="w-100 justify-content-between mt-3">
                        <form onSubmit={updateDevice}>
                            <input type="text" className="visually-hidden" name="device_id"  defaultValue={props.device.id} />
                            <div className="form-floating mb-3">
                                <input className="form-control" name="name"  id={"DeviceName"+props.device.id} placeholder="Название"  defaultValue={props.device.name} />
                                <label htmlFor={"DeviceName"+props.device.id}>Название</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" name="description"  id={"DeviceDescription"+props.device.id} placeholder="Описание"  defaultValue={props.device.description} />
                                <label htmlFor={"DeviceDescription"+props.device.id}>Описание</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="number" step="1" name="measure" className="form-control" id={"DeviceMeasure"+props.device.id} defaultValue={props.device.measure} />
                                <label htmlFor={"DeviceMeasure"+props.device.id}>{props.device.measure_units}</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" name="production_year"  id={"DeviceProductionYear"+props.device.id} placeholder="Год выпуска"  defaultValue={props.device.production_year} />
                                <label htmlFor={"DeviceProductionYear"+props.device.id}>Год выпуска</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" name="measure_units"  id={"DeviceMeasureUnits"+props.device.id} placeholder="Единицы измерения"  defaultValue={props.device.measure_units} />
                                <label htmlFor={"DeviceMeasureUnits"+props.device.id}>Единицы измерения</label>
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <button type="button" className="btn btn-danger"  onClick={e => deleteDevice(props.device.id)}>Удалить</button>
                                <button type="submit" className="btn btn-success" >Сохранить</button>
                            </div>
                            <Alert source={AlertUpdateDevice} />
                        </form>
                    </div>
                </div>


                <div className="row mt-3">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <p className="h3 text-start">Список работ</p>
                            </div>
                            <div className="col col-lg-2">
                                <div className="form-check form-switch form-check-reverse pt-2">
                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckReverse" defaultChecked={true} onClick={e => setShowIssuing(e.target.checked)}/>
                                    <label className="form-check-label" htmlFor="flexSwitchCheckReverse">Истекающие</label>
                                </div>
                            </div>
                        </div>
                        <div className="list-group">
                            <span className="list-group-item list-group-item-action">
                                <div className="d-flex w-100 justify-content-center" data-bs-toggle="collapse" data-bs-target={"#collapseNewReglament"+props.device.id}>
                                    <strong>Добавить</strong>
                                </div>
                            </span>

                            <div className="collapse" id={"collapseNewReglament"+props.device.id}>
                                <div className="card card-body mt-2 mb-2">
                                    <div className="d-flex w-100 justify-content-between">
                                        <p className="fs-5 mb-1 text-start">Для добавления регламента необходимо указать как минимум один из интервалов. Если указаны оба, то расчёт будет производиться исходя из того, который истечёт раньше.</p>
                                    </div>
                                    <form onSubmit={addReglament}>
                                        <input type="text" className="visually-hidden" name="device_id" defaultValue={props.device.id} />
                                        <div className="mb-3">
                                                    <input className="form-control" name="name"  id="DataList" placeholder="Название работы"  defaultValue={''} />
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="number" step="1" name="interval_months" className="form-control" defaultValue={0} />
                                            <span className="input-group-text" id="basic-addon2">Интервал в месяцах</span>
                                        </div>
                                        <div className="input-group mb-3">
                                                    <input type="number" step="1" name="interval_measure" className="form-control" defaultValue={0} />
                                                    <span className="input-group-text" id="basic-addon2">Интервал в {props.device.measure_units}</span>
                                        </div>
                                        <div className="mb-3">
                                                    <input className="form-control" name="description" placeholder="Описание"  defaultValue={''} />
                                        </div>
                                        <div className="d-flex w-100 justify-content-between">
                                                    <button type="submit" className="btn btn-success" >Добавить</button>
                                        </div>
                                        <Alert source={AlertAddReglament} />
                                    </form>
                                </div>
                            </div>
                            { resultReglaments.isLoading && <span className="list-group-item list-group-item-action" aria-current="false"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></span>}
                            { resultReglaments.error && <div className="text-center">Не удалось получить список работ. {resultReglaments.error.status}</div>}
                            { !resultReglaments.isLoading && resultReglaments.data.result.total === 0 && resultReglaments.data.result.reglaments.length === 0 && <span className="list-group-item" aria-current="false">Не найдено ни одного регламента.</span>}
                            { !resultReglaments.isLoading && resultReglaments.data.result.total > 0 && showIssuing && resultReglaments.data.result.reglaments.length === 0 && <span className="list-group-item" aria-current="false">Всё в норме, работ не предвидится.</span>}

                            {!resultReglaments.isLoading && resultReglaments.data.result.reglaments.map((reglament, index) =>{
                                return(
                                     <Reglament reglament={reglament} device={props.device} key={reglament.id}/>
                                )
                            })}

                        </div>

                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <div className="row m-0">
                            <div className="col">
                                <p className="h3 text-start">История работ</p>
                            </div>
                            <div className="col">
                            { resultWorks.error && <div className="text-center">Не удалось получить список проведённых работ. {resultWorks.error.status}</div>}
                            {!resultWorks.isLoading  && resultWorks.data.result.pages.length > 0  &&
                                    <div className="d-flex justify-content-end">
                                        <nav aria-label="Page navigation example">
                                              <ul className="pagination">
                                                {resultWorks.data.result.pages.map((page, index) =>{
                                                    return(
                                                        <li key={props.device + '_' + page} className={"page-item " + (page ? '' : 'disabled') + (page === resultWorks.data.result.current_page ? 'active' : '')}><button className="page-link" onClick={e => setReglamentLogPage(page)}>{page ? page : '...'}</button></li>
                                                    )
                                                })}
                                              </ul>
                                        </nav>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="list-group">
                            { resultWorks.isLoading && <span className="list-group-item" aria-current="false"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></span>}
                            { !resultWorks.isLoading && resultWorks.data.result.works.length === 0 && <span className="list-group-item" aria-current="false">Пусто</span>}

                            {!resultWorks.isLoading && resultWorks.data.result.works.map((work, index) =>{
                                return(
                                     <ReglamentLog device={props.device} work={work} key={work.id}/>
                                )
                            })}
                          </div>

                    </div>
                </div>

              </div>


            </div>

        </div>
);


}

function Service() {
const { token, getURL } = Base();
const requestTriggerValue = useTrigger(requestTriggerService);
const result = useFetch(`${getURL()}api/service/devices`, {headers: {'Authorization': `Bearer ${token}`}, depends: [requestTriggerValue]});

  if (result.isLoading) {
        return <div className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></div>;
  }

  if (result.error){
        return <div><p className="h3 text-center">Ошибка при загрузке.</p><p className="h6 text-center">{result.error.status} - {result.error.statusText}</p></div>;
  }

  return (
      <div className="container text-center">
       {result.data.result.devices.map((device, index) =>{
            return(
                 <Device device={device} key={device.id}/>
            )
        })}


      </div>
  );
}

export default Service;
