import React, { useEffect  } from "react";
//import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import useFetch from "react-fetch-hook";


import {
  Header,
  Service,
  Money,
  Settings,
  Login,
  Base,
  Dashboard,
  Footer,
  Monitoring
} from "./components";


function App() {
    const { setToken, token, getURL } = Base();
    useEffect(() => {}, [token]);
    const authStatus = useFetch(`${getURL()}api/auth/check`, { method: 'GET', headers: {'Authorization': `Bearer ${token}`}})
    if (authStatus.isLoading) {
        return (
          <Router>
            <main className="flex-shrink-0">
              <div className="text-center m-5"><div className="spinner-border" role="status"><span className="visually-hidden">Загрузка...</span></div></div>
            </main>
          </Router>
        );
    }
    if(authStatus.error){
        if (authStatus.error.status !== 401){
            return <div><p className="h3 text-center m-5">Сервис недоступен</p></div>;
        } else if (authStatus.error.status === 401) {
             return <Login setToken={setToken} />
        }
    } else {
            return (
              <Router>
                <main className="flex-shrink-0">
                  <Header />
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/service" element={<Service  />} />
                    <Route path="/money" element={<Money />} />
                    <Route path="/monitoring" element={<Monitoring />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/login" element={<Login />} />
                  </Routes>
                  <Footer />
                </main>
              </Router>
            );


    }






 }

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

serviceWorker.unregister();
