import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from "./Alert.jsx";
import Base from './Base';
import useFetch from "react-fetch-hook";


function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}


function Notification() {
    const { token, getURL } = Base();
    const result = useFetch(`${getURL()}api/notification/settings`, {headers: {'Authorization': `Bearer ${token}`}});
    const [AlertNotification, setAlertNotification] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

    const updateNotifications = async e => {
        const target = e.target;
        var data = {};
        if (target.type === 'checkbox') data[target.name] = target.checked;
        else if (target.type === 'button') data[target.name] = true;
        else data[target.name] = target.value;
        console.log(data)
        let result = await fetch(`${getURL()}api/notification/settings`, { method: 'POST', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify(data)})
        let json_data = await result.json()
        if (json_data.error === null) {
                setAlertNotification({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Изменения сохранены.'}});
        }
        else {
                setAlertNotification({'error':{'show': true, 'text': 'Не удалось сохранить изменения: '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    if (result.isLoading) {
        return <div className="mt-2 mb-5"><h1>Уведомления</h1><div className="text-end"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></div></div>;
    }

    if (result.error){
        return <div className="mt-2 mb-5"><h1>Уведомления</h1><div><p className="h3 text-end">Ошибка при загрузке.</p><p className="h6 text-end">{result.error.status} - {result.error.statusText}</p></div></div>;
    }
    console.log(result)

  return (
        <div className="mt-2 mb-5 fs-5">
            <h1>Уведомления</h1>
            <p>Вам доступны два варианта доставки уведомлений: e-mail и telegram. Уведомления (за исключением важных) будут отправляться вам с 9:00 по 21:00 в соответствии с настройками часового пояса для вашего аккаунта.</p>
            <div className="row text-start mt-2">
            <h5>E-mail</h5>
                <div className="col">
                <div className="form-check">
                  <input className="form-check-input" name="email_active" type="checkbox" onChange={updateNotifications} id="flexCheckEmail" defaultChecked={result.data.result.email.active}/>
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Отправлять уведомления на {result.data.result.email.email}
                  </label>
                </div>
                </div>
            </div>
            <div className="row text-start mt-2">
            <h5>Telegram</h5>
                { result.data.result.telegram.verified && <div className="col"><div className="form-check"><input className="form-check-input" name="telegram_active"  type="checkbox" onChange={updateNotifications}  id="flexCheckTelegram" defaultChecked={result.data.result.telegram.active}/><label className="form-check-label" htmlFor="flexCheckDefault">Отправлять уведомления</label></div><button type="button" name="telegram_unlink" className="btn btn-secondary m-2 " onClick={updateNotifications}>Отвязать аккаунт</button></div>}
                { !result.data.result.telegram.verified && <div className="col"> У вас не привязан аккаунт. Чтобы получать уведомления в Telegram выполните следующие действия:<ol className="list-group list-group-numbered m-1"><li className="list-group-item">Найдите в Telegram бота <a href="https://t.me/NezvanovLoggerBot">NezvanovLoggerBot</a> и запустите его</li><li className="list-group-item">Напишите в чат с ботом код верификации: {result.data.result.telegram.verification_code}</li></ol></div>}
            </div>
            <Alert source={AlertNotification} />
        </div>
  );
}

function Settings() {
    const { token, getURL } = Base();
    const result = useFetch(`${getURL()}api/auth/account`, {headers: {'Authorization': `Bearer ${token}`}});
    const [AlertPersonalData, setAlertPersonalData] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});
    const [AlertLogout, setAlertLogout] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});
    const [AlertPassword, setAlertPassword] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});
    const [AlertMain, setAlertMain] = useState({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});

    const logout = async e => {
        let result = await fetch(`${getURL()}api/auth/token`, { method: 'DELETE', headers: {'Authorization': `Bearer ${token}`}})
        let json_data = await result.json()
        if (json_data.error === null) {
                setAlertLogout({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Вы успешно вышли из системы.'}});
                await timeout(1000);
                localStorage.removeItem('token');
                window.location.href = '/';
        }
        else {
                setAlertLogout({'error':{'show': true, 'text': 'Не удалось выйти из системы: '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    };

    const logout_all = async e => {
        let result = await fetch(`${getURL()}api/auth/token/all`, { method: 'DELETE', headers: {'Authorization': `Bearer ${token}`}})
        let json_data = await result.json()
        if (json_data.error === null) {
                setAlertLogout({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Вы успешно вышли из системы на всех устройствах.'}});
                await timeout(1000);
                localStorage.removeItem('token');
                window.location.href = '/';
        }
        else {
                setAlertLogout({'error':{'show': true, 'text': 'Не удалось выйти из системы: '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    };

    const logout_others = async e => {
        let result = await fetch(`${getURL()}api/auth/token/others`, { method: 'DELETE', headers: {'Authorization': `Bearer ${token}`}})
        let json_data = await result.json()
        if (json_data.error === null) {
                setAlertLogout({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Вы успешно вышли из системы на всех устройствах, кроме этого.'}});
                await timeout(1000);
        }
        else {
                setAlertLogout({'error':{'show': true, 'text': 'Не удалось выйти из системы: '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    };

    const updatePersonalData = async e => {
        const target = e.target;
        var data = {};
        data[target.name] = target.value;
        let result = await fetch(`${getURL()}api/auth/account`, { method: 'POST', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify(data)})
        let json_data = await result.json()
        if (json_data.error === null) {
                setAlertPersonalData({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Изменения сохранены.'}});
        }
        else {
                setAlertPersonalData({'error':{'show': true, 'text': 'Не удалось сохранить изменения: '+json_data.error}, 'success': {'show': false, 'text': ''}});
        }
    }

    const updatePassword = async e => {
        e.preventDefault();
        setAlertPassword({'error':{'show': false, 'text': ''}, 'success': {'show': false, 'text': ''}});
        let current_password = e.target.elements.password_current.value
        let new_password = e.target.elements.password_new_1.value
        let new_password_repeat = e.target.elements.password_new_2.value
        if(current_password.length > 0 && new_password.length > 0 && new_password === new_password_repeat){
            let data = {'old_password': current_password, 'new_password': new_password}
            let result = await fetch(`${getURL()}api/auth/password`, { method: 'POST', headers: {'Authorization': `Bearer ${token}`,  'Content-Type': 'application/json'}, body: JSON.stringify(data)})
            let json_data = await result.json()
            if (json_data.error === null) {
                    setAlertPassword({'error':{'show': false, 'text': ''}, 'success': {'show': true, 'text': 'Пароль изменён.'}});
            }
            else {
                    setAlertPassword({'error':{'show': true, 'text': 'Не удалось изменить пароль: '+json_data.error}, 'success': {'show': false, 'text': ''}});
            }
        } else {
            setAlertPassword({'error':{'show': true, 'text': 'Не указан текущий пароль или не совпадают новые пароли.'}, 'success': {'show': false, 'text': ''}});
        }



    }

    if (result.isLoading) {
        return <div className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></div>;
    }

    if (result.error){
        return <div><p className="h3 text-center">Ошибка при загрузке.</p><p className="h6 text-center">{result.error.status} - {result.error.statusText}</p></div>;
    }
    console.log(result)

  return (
<div className="container text-end mt-3 mb-3">
              <Alert source={AlertMain} />
              <div className="row justify-content-md-center">
                    <div className="col-lg text-start">
                          <h1>Выход</h1>



                          <div className="row m-3">
                                <div className="col d-flex w-100 justify-content-around m-1">
                                        <button type="button" className="btn btn-secondary m-2 " onClick={logout_all}>Выйти на всех устройствах</button>
                                        <button type="button" className="btn btn-secondary m-2 " onClick={logout_others}>Выйти на всех устройствах, кроме этого</button>
                                        <button type="button" className="btn btn-primary m-2" onClick={logout}>Выйти только на этом устройстве</button>

                                </div>
                                <Alert source={AlertLogout} />
                          </div>


                            <Notification />
                    </div>


                    <div className="col-lg text-start">

                                                <div className="mt-2">
                                                        <h1>Личные данные</h1>

                                                        <div className="col fs-5">Для изменения личных данных внесите правки в поля, изменения сохранятся автоматически. </div>


                                                        <form>
                                                                <div className="form-floating mb-3">
                                                                        <input type="text" name="email" id="email_field" className="form-control" placeholder="E-mail" onChange={updatePersonalData} value={result.data.result.email}   />
                                                                        <label htmlFor="email_field" >E-mail</label>
                                                                </div>
                                                                <div className="form-floating mb-3">
                                                                        <input type="text" name="first_name" id="first_name_field" className="form-control" placeholder="Имя" onChange={updatePersonalData} defaultValue={result.data.result.first_name}  />
                                                                        <label htmlFor="first_name_field" >Имя</label>
                                                                </div>
                                                                <div className="form-floating mb-3">
                                                                        <input type="text" name="last_name" id="last_name_field" className="form-control" placeholder="Имя" onChange={updatePersonalData} defaultValue={result.data.result.last_name}  />
                                                                        <label htmlFor="last_name_field" >Фамилия</label>
                                                                </div>
                                                                <div className="form-floating mb-3">
                                                                        <input type="text" name="timezone" id="timezone_field" className="form-control" placeholder="Часовой пояс" onChange={updatePersonalData} defaultValue={result.data.result.timezone}  />
                                                                        <label htmlFor="last_name_field" >Часовой пояс (относительно UTC)</label>
                                                                </div>
                                                                <Alert source={AlertPersonalData} />
                                                        </form>
                                                </div>
                                                <div className=" mt-2 mb-5">
                                                <h1>Смена пароля</h1>
                                                        <form onSubmit={updatePassword}>
                                                                <div className="form-floating mb-3">
                                                                        <input type="password" name="password_current" id="password0" className="form-control"   />
                                                                        <label htmlFor="password0" >Введите текущий пароль</label>
                                                                </div>
                                                                <div className="form-floating mb-3">
                                                                        <input type="password" name="password_new_1" id="password1" className="form-control"  />
                                                                        <label htmlFor="password1" >Введите новый пароль</label>
                                                                </div>
                                                                <div className="form-floating mb-3">
                                                                        <input type="password" name="password_new_2" id="password2" className="form-control"  />
                                                                        <label htmlFor="password2" >Повторите новый пароль</label>
                                                                </div>
                                                                <Alert source={AlertPassword} />
                                                                <div className="d-flex w-100 justify-content-center">
                                                                        <button type="submit" className="btn btn-primary" >Сменить пароль</button>
                                                                </div>

                                                        </form>
                                                </div>



                    </div>
              </div>
        </div>
  );
}

export default Settings;
