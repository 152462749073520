function Footer() {



  return (
    <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-2 mt-4 border-top">
            <div className="col-md-4 d-flex align-items-center">
                <span className="mb-2 mb-md-0 text-body-secondary">&copy; 2022 Alexander Nezvanov</span>
            </div>

            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
                <li className="ms-3"><a className="text-body-secondary" href="https://t.me/nezvanovml"><i className="bi bi-telegram"></i></a></li>
            </ul>
        </footer>
    </div>
  );
}

export default Footer;
