import React from "react";
import { NavLink  } from "react-router-dom";


function Header() {
  return (
    <div className="container">
        <header className="d-flex flex-wrap justify-content-between py-3 mb-4 border-bottom">
          <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
            <span className="fs-4">NLogger</span>
          </a>
          <ul className="nav nav-pills">
            <li className="nav-item"><NavLink to="/Service" className="nav-link">Сервис</NavLink></li>
            <li className="nav-item"><NavLink to="/Money" className="nav-link">Деньги</NavLink></li>
            <li className="nav-item"><NavLink to="/Monitoring" className="nav-link">Мониторинг</NavLink></li>
            <li className="nav-item"><NavLink to="/Settings"  className="nav-link">Настройки</NavLink></li>
          </ul>
        </header>
    </div>
  );
}

export default Header;
