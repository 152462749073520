import React, { useState, useEffect } from "react";
import Alert from "./Alert.jsx";
import Base from './Base';
import useFetch from "react-fetch-hook";


function ServiceDashboard() {
    const { token, getURL } = Base();
    const resultDashboard = useFetch(`${getURL()}api/service/dashboard`, {headers: {'Authorization': `Bearer ${token}`}});


    return (
          <div className="col">

                        <div className="row">
                            <div className="col">
                                <p className="h3 text-start">Список работ</p>
                            </div>
                        </div>
                        <div className="list-group">
                            { resultDashboard.isLoading && <span className="list-group-item list-group-item-action" aria-current="false"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></span>}
                            { resultDashboard.error && <div className="text-center">Не удалось получить список работ. {resultDashboard.error.status}</div>}
                            { !resultDashboard.isLoading && resultDashboard.data.result.reglaments.total === 0 && <span className="list-group-item" aria-current="false">Всё в норме, работ не предвидится.</span>}


                            {!resultDashboard.isLoading && resultDashboard.data.result.reglaments.issued.map((reglament, index) =>{
                                return(
                                     <span className="list-group-item list-group-item-action" aria-current="false" key={reglament.id}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="d-flex w-100 justify-content-between mb-1">
                                                <div className="col-md-auto">
                                                    <p className="mb-1 text-start fs-6">
                                                        <strong>{reglament.device}</strong> : {reglament.name}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="progress" role="progressbar" aria-label="Progress" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <div className={"progress-bar "+ (reglament.expiration_percent >= 100 ? "bg-danger" : (reglament.expiration_percent > 80 ? "bg-warning" : "bg-success"))} style={{width: reglament.expiration_percent+"%"}}>
                                                {reglament.expiration_percent}%
                                            </div>
                                        </div>



                                    </span>
                                )
                            })}
                            {!resultDashboard.isLoading && resultDashboard.data.result.reglaments.issuing.map((reglament, index) =>{
                                return(
                                     <span className="list-group-item list-group-item-action" aria-current="false" key={reglament.id}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <div className="d-flex w-100 justify-content-between mb-1">
                                                <div className="col-md-auto">
                                                    <p className="mb-1 text-start fs-6">
                                                        <strong>{reglament.device}</strong> : {reglament.name}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="progress" role="progressbar" aria-label="Progress" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <div className={"progress-bar "+ (reglament.expiration_percent >= 100 ? "bg-danger" : (reglament.expiration_percent > 80 ? "bg-warning" : "bg-success"))} style={{width: reglament.expiration_percent+"%"}}>
                                                {reglament.expiration_percent}%
                                            </div>
                                        </div>



                                    </span>
                                )
                            })}
                        </div>




            </div>


);


}

function Dashboard({ token }) {



  return (
        <div className="container text-center mb-3">
            <ServiceDashboard/>
        </div>
  );
}

export default Dashboard;
